import axios from "axios/index";

class Auth {
  static TOKEN_NAME = 'token';

  constructor() {
    // this.authenticated = false;
  }

  static async login(username, password, successCallback, errorCallback) {
    await axios.post('/api/authenticate', {
      username: username,
      password: password
    }).then(successCallback).catch(errorCallback);
  }



  static async logout(successCallback, errorCallback) {
    await axios.get('/api/logout').then(successCallback).catch(errorCallback);
  }

  

  static async loadCurrentUser() {
    axios.get('/api/checkToken').then(
      
      (response) => {
        console.log(response);
        if (response.status === 200) {
          this.authenticated = true;
        } else {
          this.authenticated = false;
        }
      }
    ).catch((err) => {
      this.authenticated = false;
    });
  }

  static setAuthenticated(state) {
    this.authenticated = state;
  }

  static getAuthenticated() {
    console.log("get authenticated " + this.authenticated);
    return this.authenticated;
  }

  // static async isAuthenticated() {
  //   try{
  //     await axios.get('/checkToken').then(
  //       response => {
  //         alert("res status: " + response.status)
  //         if (response.status === 200) {
  //           this.authenticated = true;
  //         } else {
  //           this.authenticated = false;
  //         }
  //       }
  //     ).catch(err => {
  //       alert("err: " + err);
  //       this.authenticated = false;
  //     });
  //   }catch(err){
  //     this.authenticated = false;
  //     alert("catch : " + err);
  //   }


  //   alert("returning:" + this.authenticated);
  //   return this.authenticated;
  // }
}

export default Auth;
