import React from 'react';

import './NavigationItems.css';
import NavigationItem from './NavigationItem/NavigationItem';




const navigationItems = (props) => (



    <ul className="NavigationItems" onClick={props.clicked}>

        {/* {console.log("Myrole.... see: " + props.role)} */}
        
        {(() => {
        switch (props.role) {
          case "admin":   
            return <>
            <NavigationItem link="/edit_project" exact>Projects</NavigationItem>
            {/* <NavigationItem link="/edit_project_others" exact>Other Projects</NavigationItem> */}
            <NavigationItem link="/edit_cert">Certificates</NavigationItem>
            <NavigationItem link="/edit_clients">Clients</NavigationItem>
            </>;
          case "public": 
            return <>
            <NavigationItem link="/" exact>HOME</NavigationItem>
            <NavigationItem link="/about">ABOUT</NavigationItem>
            <NavigationItem link="/project">PROJECT</NavigationItem>
            <NavigationItem link="/contact">CONTACT US</NavigationItem>
            <NavigationItem link="/cert">CERTIFICATIONS</NavigationItem>
            </>;
          default:      return <></>;
        }
      })()}


        {/* {(auth.getRole()==='admin')?
        <>
        <NavigationItem link="/admin" exact>PROJECTS</NavigationItem>
        <NavigationItem link="/admin/cert">CERTIFICATES</NavigationItem>
        </>:
        <>
        <NavigationItem link="/" exact>HOME</NavigationItem>
        <NavigationItem link="/about">ABOUT</NavigationItem>
        <NavigationItem link="/project">PROJECT</NavigationItem>
        <NavigationItem link="/contact">CONTACT US</NavigationItem>
        </>} */}

    </ul>
);

export default navigationItems;