import React, { Component } from 'react';
import Auth from "../Auth";
// import UserService from '../UserService';
import { Button, Form } from 'react-bootstrap';

// import axios from 'axios';

import './Login.css';
import logoImage from '../../../assets/Images/logo.png';

import loginBk from '../../../assets/Images/admin/loginBk.jpeg';

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            loading: false,
            errorMessage: undefined
        };
    }

    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value
        });
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        Auth.login(this.state.username,
            this.state.password,
            this.handleLoginResponse,
            this.handleLoginError);
    }

    handleLoginResponse = (response) => {
        if (response.data && response.status === 200) {
            console.log("Goto: "+this.props.location.pathname);
            Auth.setAuthenticated(true);
            this.props.history.push("/edit_project");
        } else {
            this.setState({ loading: false, errorMessage: this.loginError });
        }
    };

    handleLoginError = (err) => {
        if (err.response && err.response.status === 401)
            this.setState({ loading: false, errorMessage: err.response.data.error });
        else
            this.setState({ loading: false, errorMessage: this.loginError });
    };

    componentDidMount() {
        var state = Auth.getAuthenticated();
        if (state) {
            this.setState({ loading: false});
            this.props.history.push("/edit_project")
        } else {
            // this.setState({ loading: false, errorMessage: 'Please login.' });
        }
    }

    render() {

        const errorMessageDiv = this.state.errorMessage &&
            <div className="text-danger mb-2">{this.state.errorMessage}</div>;

        const loadingDiv = this.state.loading &&
            <div className="d-flex align-items-center justify-content-center overlay">
                <div className="spinner-border text-primary" role="status" />
            </div>;


        return (
            <div className="pimg" style={{ backgroundImage: "url(" + loginBk + ")" }}>
                <div className="login-form">
                    <Form onSubmit={this.onSubmit}>
                        <img src={logoImage} alt="logo" className="LearnMoreLogo" />
                        <h3 className="companyName">Sanyo Engineering Pte Ltd</h3>
                        {loadingDiv}
                        {errorMessageDiv}
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="text"
                                placeholder="Username"
                                value={this.state.username}
                                name="username"
                                onChange={this.handleInputChange}
                                required />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Control type="password"
                                placeholder="Password"
                                value={this.state.password}
                                onChange={this.handleInputChange}
                                name="password"
                                required />
                        </Form.Group>
                        {/* <Button  className="btn btn-primary btn-block" variant='primary' onClick={() => {
                        auth.login(() => {
                            this.props.history.push("/edit_project");
                        });
                    }}>Login</Button> */}
                        <Button className="btn btn-primary btn-block" variant='primary' type="submit" value="Submit" >Login</Button>

                        <div className="webSiteLink">Visit: <a href="/">sanyoeng.com</a></div>
                    </Form>

                </div>

            </div>
        );
    }
}

export default Login;

