import React from 'react';
// import Switch from "react-switch";
import ToggleSwitch from './ToggleSwitch';
import {  Col, Form, Row } from 'react-bootstrap';

const CertEditDetails = (props) => {
    // const [project, setProject] = useState(props.project);


    return (
        <div className="tabContentMain">
            <br></br>
            <Form.Group as={Row} controlId={props.keyId+"heading"}>
                <Form.Label column sm={4}>
                    Certificate Name:
                                </Form.Label>
                <Col sm={8}>
                    <Form.Control type="text" placeholder="Certificate Name"
                     name="heading"
                        value={props.heading} 
                        required
                        onChange={props.handleProjectsOnChange} />
                </Col>
            </Form.Group>
            
            <Form.Group as={Row} controlId={props.keyId+"visible"}>
                <Form.Label column sm={4}>
                    Visible:
                                </Form.Label>
                <Col sm={8} className="toggleLeft">
                    <ToggleSwitch visible={props.visible} handleProjectsOnChange={props.handleProjectsOnChange}/>
                    {/* <Form.Control required type="text" placeholder="Visible"
                     name="visible"
                        value={props.visible} onChange={props.handleProjectsOnChange} /> */}
                </Col>
            </Form.Group>
        </div>
    );
}


export default CertEditDetails;