import React, { useState ,useEffect} from 'react';
import './projectCard.css';
import './notie.css';

import { Button, Tabs, Tab } from 'react-bootstrap';

import DeleteImage from './DeleteImage';
import Reorder from './Reorder';
import EditDetails from './EditDetails';
import AddNewImage from './AddNewImage';
import notie from "notie";
import axios from 'axios';

 //["alert", "error", "info", "information", "success", "warning"]


const ProjectCardAddNew = (props) => {
    let imgFolderPath = '/admin/project/cal/';
    
    // const [project, setProject] = useState(props.project);
    const [inputValues, setInputValues] = useState({
        keyId: props.project.keyId,
        sequence: props.project.sequence,
        visible: props.project.visible,
        heading: props.project.heading,
        description: props.project.description,
        client: props.project.client,
        mainContractor: props.project.mainContractor,
        scope: props.project.scope
    });

    //  console.log(inputValues.keyId)
    // props.updateTittle(inputValues.heading);

    const [colImageList, setColImageList] =useState(props.colImageList);

    const handleOnChange = event => {
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const saveButton = async e => {
        e.preventDefault();
        // console.log(props.indexId + "data: " + JSON.stringify(inputValues));
        if(inputValues.heading && inputValues.description && inputValues.scope && inputValues.client && inputValues.mainContractor)
            postProject(inputValues,colImageList);
        else
            notie.alert({ type: 'warning', text: 'Please Fill in all details', time: 2 });
    }

    const postProject = async (inputValues,colImageList)=> {        
        var self = props;

        var keyId = -1;
        var tempKeyId = Math.max.apply(Math, props.projectsWithImg.map(function(o) { return o.keyId; }));
        if(parseInt(tempKeyId) > 0)
          keyId = tempKeyId;

        
        inputValues.keyId=(keyId + 1).toString();

        // console.log(inputValues);
        const sendingJson = {
            ...inputValues,
            colImageList:colImageList
        }
        // console.log(sendingJson)
        await axios.post('/api/addNewProject', {
            sendingJson,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function(response){
            // console.log(response);
            if (response.data && response.status === 200) {
                notie.alert({ type: 'success', text: 'Project Added Successfully.', time: 2 });
                //props.projectsWithImg old
                var projects = [...self.projectsWithImg];
                //console.log(JSON.stringify(projects));
                projects.push(sendingJson);
                //sendingJson new
                // projects.sort(function(a, b){
                //     return a.sequence-b.sequence;
                // });

                // console.log(projects)

                if (sendingJson.visible === "true") {
                    self.accBgColor[sendingJson.keyId] = "AccColorNormal";
                } else {
                    self.accBgColor[sendingJson.keyId] = "AccColorNotVisible";
                }

                // self.setBgColor(self.accBgColor);

                self.accTittle[sendingJson.keyId] = sendingJson.heading;

                // self.setTitle(self.accTittle);

                self.setProjects(projects);
                // console.log(projects);
                
                emptyAll();

                // console.log("go:"+ JSON.stringify(inputValues));
            }
        }).catch(function(err){
            console.log(err);
            notie.alert({ type: 'error', text: err, time: 2 });
        });
      }


      const emptyAll=()=>{
        setInputValues({
            keyId: "9999",
            sequence: "",
            visible: "true",
            heading: "",
            description: "",
            client: "",
            mainContractor:"",
            scope: ""
        })
        setColImageList([])
        setButtonColorClass("primary saveButton");
      }

    //   const successSaving = (response) => {
    //     if (response.data && response.status === 200) {
    //         notie.alert({ type: 'success', text: 'Project Added Successfully.', time: 2 });
    //         //props.projectsWithImg old
    //         //sendingJson new
    //         //props.setProjects set in it.
    //     }
    // };
    //  const errorSaving = (err) => {
    //     console.log(JSON.stringify(err));
    //     notie.alert({ type: 'error', text: JSON.stringify(err), time: 2 });
    // };
    
    let firstLoad = false;
    const [buttonColorClass , setButtonColorClass] = useState("primary saveButton");
    
    useEffect(() => {
        firstLoad = true;
    }, [props])

    let colorChange=true;
    useEffect(() => {
        if(!firstLoad){
            if(buttonColorClass==="primary saveButton"){
                setButtonColorClass("danger saveButton");
                // props.changesFound(inputValues.keyId,"AccColorDanger");
                // console.log("change found...");
            }
        }
    }, [inputValues,colImageList])

    return (
        <div>

            <div className="projectCardEditMainClass">

                <Tabs justify id="uncontrolled-tab-example" defaultActiveKey="Details">
                    <Tab eventKey="Details" title="Details">
                        <EditDetails
                            key={props.index}
                            keyId={inputValues.keyId}
                            // project={project}
                            sequence={inputValues.sequence}
                            visible={inputValues.visible}
                            heading={inputValues.heading}
                            description={inputValues.description}
                            client={inputValues.client}
                            mainContractor={inputValues.mainContractor}
                            scope={inputValues.scope}
                            handleProjectsOnChange={handleOnChange}
                        />
                    </Tab>
                    <Tab eventKey="Reorder" title="Reorder">
                        <Reorder colImageList={colImageList}  setColImageList={setColImageList}/>
                    </Tab>
                    <Tab eventKey="Delete" title="Delete">
                        <DeleteImage colImageList={colImageList} keyId={inputValues.keyId}
                        setColImageList={setColImageList} />
                    </Tab>
                    <Tab eventKey="Upload" title="Upload">
                        <AddNewImage colImageList={colImageList}  setColImageList={setColImageList}
                        imgFolderPath={imgFolderPath}
                         keyId={inputValues.keyId} numberOfPhoto={colImageList.length} />
                    </Tab>
                </Tabs>
                
                
                <Button key={props.index} disabled={buttonColorClass.toUpperCase().indexOf("PRIMARY") > -1} 
                variant={buttonColorClass} onClick={saveButton} >ADD PROJECT</Button>
            </div>
        </div>
    );

}

export default ProjectCardAddNew;