import React, { Component } from 'react';
import './Project.css';
import projectBk from '../../assets/Images/project/1.png';
import ProjectCard from '../../components/UI/ProjectCard/projectCard';
import ScrollAnimation from 'react-animate-on-scroll';
import { Card, Container, Table } from 'react-bootstrap';
import Layout from '../../hoc/Layout/Layout';
import axios from 'axios';

class Project extends Component {


  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      projectsWithImg: [],
      projectInTable: []
    }
  }
  scrollToMyRef = () => {
    window.scrollTo({
      top: this.myRef.current.offsetTop,
      // top:250,
      behavior: 'smooth'
    })
  }


  componentDidMount() {
    
    axios.get('/api/getContent')
      .then(response => {
        // console.log(response.data);
        this.setState({
          projectsWithImg: response.data.projectsWithImg,
          projectInTable: response.data.projectsInTable
        })
      })
  }


  render() {
    

    return (
      <div className="projectMain">
      <Layout role="public">
       
          <div className="pimg" style={{ backgroundImage: "url(" + projectBk + ")" }}>
            <div className="ptext">
              <span className="borders">
                SANYO'S PROJECTS
            </span>
            </div>
          </div>

          <div ref={this.myRef}>
          {this.state.projectsWithImg.filter(project => project.visible==="true").map((project, index)=> (
            <ProjectCard
            key={index}
            sequence={index}
            header={project.heading}
            colImageList={project.colImageList}
            description={project.description}
            mainContractor={project.mainContractor}
            client={project.client}
            scope={project.scope}/>
          ))}
          </div>
          <section className="section section-light2">
            <ScrollAnimation animateIn="slideInRight" offset={50}>
              <h2 className="my-header">OTHER SANYO'S PROJECTS</h2>
            </ScrollAnimation>
          </section>
          <div className="pimg lastHeading" style={{ backgroundImage: "url(" + projectBk + ")" }}>
            <Container className="moreProjectTable">
              <Card>
                <Card.Body>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        {/* <th>#</th> */}
                        <th>Client/Owner</th>
                        <th>Main Contractor</th>
                        <th>Description of Project</th>
                        <th>Scope</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.projectInTable.map((project, index) => (

                        <tr key={index}>
                          {/* <td>{project.id}</td> */}
                          <td>{project.client}</td>
                          <td>{project.mainContractor}</td>
                          <td>{project.description}</td>
                          <td>{project.scope}</td>
                        </tr>

                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>

            </Container>

          </div>

        

          <div onClick={this.scrollToMyRef} className="arrow bounce" />
        
      </Layout>
      </div>
    );
  }
}

export default Project;