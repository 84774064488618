import React, { useState, useEffect } from 'react';
import './projectCard.css';
import './notie.css';

import { Button} from 'react-bootstrap';
import notie from "notie";
import axios from 'axios';
// import styled from "styled-components";
import { ReactSortable } from "react-sortablejs";
import { Col, Row } from 'react-bootstrap';
import ProjectRow from './projectRow';

// const Item = styled.div`
//   display: flex;
//   padding: 0.3rem;
//   border-radius: 0.2rem;
//   background-color: #fff6;
//   margin-bottom: 0.3rem;
//   &.sortable-ghost {
//     background-color: #c8ebfb;
//   }
// `;

// const DragItem = styled(Item)`
//   &.sortable-selected {
//     background-color: #0a0a;
//   }
// `;

// let firstLoad = false;
// let counter = 0;
const OtherProjects = (props) => {

// console.log("counter main:"+ counter);

  const [projects, setProjects] = useState([]);


  const [buttonColorClass, setButtonColorClass] = useState("primary saveButton");

  useEffect(() => {
    // console.log('use Effect Props');
    setProjects(props.projectInTable);
    setCheckBoxListState(checkBoxList);
  }, [props])

  // useEffect(() => {
  //   // console.log("UonProps.projectInTable:"+ props.projectInTable.length+ counter)
  //   if (props.projectInTable.length !== 0 && counter <= 0) {
  //     // console.log("UonProps:setting firstload true")
  //     firstLoad = true;
  //   }
  // }, [props.projectInTable])



  // useEffect(() => {
   
  //   // console.log("useEffect:projects:proLeng: " + projects.length + "-"+ firstLoad );
  //   if (firstLoad) {
  //     counter++;
  //     // console.log("++c:"+ counter);
  //   }
  //   if (projects.length !== 0 && counter > 1) {
  //     if (buttonColorClass === "primary saveButton") {
  //       props.changesFoundTableProject("AccColorDanger");
  //       setButtonColorClass("danger saveButton");
  //       firstLoad = false;
  //       // console.log("change color now..");
  //     }
  //   }
  //   // console.log("useEffect:projects:end " +firstLoad+":"+ counter);
  // }, [projects])

  const saveButton = async e => {
    e.preventDefault();
    saveFunction(projects, postProject);

  }

  const postProject = async (projectsInTable) => {
    await axios.post('/api/saveProjectInTable', {
      projectsInTable,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(function(response){
      // console.log(JSON.stringify(response));
      // console.log("successSaving:"+ JSON.stringify(projects));
      if (response.data && response.status === 200) {
        // if (buttonColorClass === "danger saveButton") {
          // props.changesFoundTableProject("AccColorNormalTable");
          props.setProjectInTable(projectsInTable);
        //   setButtonColorClass("primary saveButton");
        // }
        notie.alert({ type: 'success', text: 'Project Details Saved Successfully.', time: 2 });
      }
    }).catch(function(err){
      // console.log(JSON.stringify(err));
      notie.alert({ type: 'error', text: JSON.stringify(err), time: 2 });
    });
  }
  // const successSaving = (response) => {
  //   console.log(JSON.stringify(response));
  //   console.log("successSaving:"+ JSON.stringify(projects));
  //   if (response.data && response.status === 200) {
  //     if (buttonColorClass === "danger saveButton") {
  //       props.changesFoundTableProject("AccColorNormalTable");
  //       props.setProjectInTable(projectsInTable);
  //       setButtonColorClass("primary saveButton");
  //     }
  //     notie.alert({ type: 'success', text: 'Project Details Saved Successfully.', time: 2 });
  //   }
  // };
  // const errorSaving = (err) => {
  //   // console.log(JSON.stringify(err));
  //   notie.alert({ type: 'error', text: JSON.stringify(err), time: 2 });
  // };

  const deleteSeleteRow = async e => {
    e.preventDefault();
    notie.confirm({
      text: 'Are you sure you wish to delete selected project?',
      cancelCallback: function () {
        notie.alert({ type: 3, text: 'Aw, why not? :(', time: 2 })
      },
      submitCallback: function () {
        var tempProjects = [...projects]; 

        for (var i = tempProjects.length - 1; i > 0; i--) {
          if (checkBoxListState[tempProjects[i].id]) {
             tempProjects.splice(i,1);
          }
        }

        setProjects(tempProjects);
        notie.alert({ type: 1, text: 'Selected Row deleted. Please Save Changes.', time: 2 })
        setDisableDeleteBtn(true);
        
        saveFunction(tempProjects, postProject);
      }
    });


  }

  let checkBoxList = {};


  let len = projects.length;
  for (var x = 0; x < len; x++) {
    checkBoxList[projects[x].id] = false;
  }
  const [checkBoxListState, setCheckBoxListState] = useState({});
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(true);


  const onChangeCheckBox = event => {
    const { id, name, value, checked } = event.target;

    var modifyState = { ...checkBoxListState };
    modifyState[id] = checked;
    setCheckBoxListState(modifyState);

    setDisableDeleteBtn(true);
    Object.entries(modifyState).map(([key, value]) => {
      if (value === true)
        setDisableDeleteBtn(false);
    })
  }


  const addNewRow = async e => {
    e.preventDefault();
    let lastId = 0;
    for(var i = 0; i < projects.length; i++){
      if(parseInt(projects[i].id) >= lastId){
        lastId = parseInt(projects[i].id);
      }
    }
    lastId++;
    var nextId = lastId;
    // console.log("nextId:" + nextId);
    let newProject = {
      "id": String(nextId),
      "client": "",
      "mainContractor":"",
      "scope": "",
      "description": "",
      "chosen": false
    }
    var newProjectAdded = [...projects];
    newProjectAdded.push(newProject)
    setProjects(newProjectAdded);
    props.setProjectInTable(newProjectAdded);
    
  }
  
  return (

    <div>
      <Row>
        <Col className='col-md-3 heading'>CLIENT/OWNER</Col>
        <Col className='col-md-3 heading'>MAIN CONTRACTOR</Col>
        <Col className='col-md-2 heading'>DESCRIPTION</Col>
        <Col className='col-md-3 heading'>SCOPE</Col>
        <Col className='col-md-1 heading'></Col>
      </Row>
      <ReactSortable
        list={projects}
        
        setList={newState => setProjects(newState)}>
        {projects.map(item => (
          <ProjectRow
            projects={projects}
            setProjects={setProjects}
            key={item.id}
            project={item}
            checkBoxValue={checkBoxListState[item.id] || false}
            onChangeCheckBox={onChangeCheckBox}
          />
        ))}
      </ReactSortable>
      <Button className="multiProjectButton" variant="info"
        onClick={addNewRow}>Add new project</Button>
      <Button className="multiProjectButton" variant="danger"
        disabled={disableDeleteBtn}
        onClick={deleteSeleteRow}>Delete selected</Button>
      <Button className="multiProjectButton" onClick={saveButton}
        // disabled={buttonColorClass.toUpperCase().indexOf("PRIMARY") > -1}
        variant={buttonColorClass}>Save changes</Button>
      {/* <Button className="multiProjectButton" variant="secondary" >Discard changes</Button> */}
    </div>
  );
}

export default OtherProjects;

function saveFunction(projects, postProject, successSaving, errorSaving) {
  var proceedSave = true;
  // console.log("data: " + JSON.stringify(projects));
  for (var i = 0; i < projects.length; i++) {
    if (!(projects[i].client && projects[i].mainContractor && projects[i].scope && projects[i].description)) {
      proceedSave = false;
    }
  }
  if (proceedSave) {
    postProject(projects);
    // firstLoad = false;
    // counter = -1;
  }
  else {
    notie.alert({ type: 'warning', text: 'Please Fill in all details', time: 4 });
  }
}

