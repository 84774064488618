import React, { useState} from 'react';
import './projectCard.css';
import './notie.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import styled from "styled-components";
import {  Form } from 'react-bootstrap';

const Item = styled.div`
  display: flex;
  padding: 0.3rem;
  border-radius: 0.2rem;
  background-color: #fff6;
  margin-bottom: 0.3rem;
  &.sortable-ghost {
    background-color: #c8ebfb;
  }
`;

const DragItem = styled(Item)`
  &.sortable-selected {
    background-color: #0a0a;
  }
`;


const ProjectRow = (props) => {



  const [inputValues, setInputValues] = useState({
    id: props.project.id,
    description: props.project.description,
    client: props.project.client,
    mainContractor: props.project.mainContractor,
    scope: props.project.scope
  });

  const handleOnChange = event => {
    const { id, name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });

    let len = props.projects.length;
    for (var x = 0; x < len; x++) {
      if (id === props.projects[x].id) {
        if (name === "client") {
          props.projects[x].client = value;
        } else if (name === "scope") {
          props.projects[x].scope = value;
        }
        else if (name === "mainContractor") {
          props.projects[x].mainContractor = value;
        } else if (name === "description") {
          props.projects[x].description = value;
        }

      }
    }
    props.setProjects(props.projects);
  };

  return (
    <DragItem key={inputValues.id}>
      <FontAwesomeIcon icon={faArrowsAlt} className="faIcon" />
      {/* <div className='row'> */}
        <div className='col-md-3'>
          <Form.Control as="textarea" rows="1" placeholder="client"
            name="client"
            id={inputValues.id}
            value={inputValues.client}
            onChange={handleOnChange}
          />
        </div>
        <div className='col-md-3'>
          <Form.Control as="textarea" rows="1" placeholder="main contractor"
            name="mainContractor"
            id={inputValues.id}
            value={inputValues.mainContractor}
            onChange={handleOnChange}
          />
        </div>
        <div className='col-md-2'>
          <Form.Control as="textarea" rows="1" placeholder="description"
            name="description"
            id={inputValues.id}
            value={inputValues.description}
            onChange={handleOnChange}
          />
        </div>
        <div className='col-md-4'>
          <div className='row'>
            <div className='col-md-11'>
              <Form.Control as="textarea" rows="1" placeholder="scope"
                name="scope"
                value={inputValues.scope}
                id={inputValues.id}
                onChange={handleOnChange}
              />
              </div>
            {/* <div className='col-md-1'>
              {inputValues.id}
            </div> */}
            <div className='col-md-1'>
              <Form.Check type="checkbox"
                name="checkbox"
                className="otherCheck"
                // checked={props.checkBoxList[inputValues.id - 1] != undefined ? props.checkBoxList[inputValues.id - 1].val || false : false}
                onChange={props.onChangeCheckBox}
                checked={props.checkBoxValue}
                id={inputValues.id} />
            </div>
          {/* </div> */}
        </div>
      </div>
    </DragItem>
  );
}

export default ProjectRow;

