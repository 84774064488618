import React, { useState, useEffect } from 'react';
import Gallery from "react-photo-gallery";

import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Photo from "./Photo";


const Reorder = (props) => {
    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    
    const photos = props.colImageList.map((item, index) => {
        return {
            key: index.toString(),
            src: process.env.PUBLIC_URL + item,
            width: 1,
            height: 1
        };
    }
    );

    const [items, setItems] = useState(photos);

    useEffect(() => {
        setItems(photos);
    }, [props])
    
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newArr= arrayMove(items, oldIndex, newIndex);
        setItems(newArr);
        

        saveReorder(newArr);
        
    };
    const SortablePhoto = SortableElement(item => <Photo {...item} />);
    const SortableGallery = SortableContainer(({ items }) => (
        <Gallery photos={items} renderImage={props => <SortablePhoto {...props} />} />
    ));

    const saveReorder = (newArr) => {
        const newImageList = newArr.map((item) => item.src);
        // console.log("Set: "+JSON.stringify(newImageList));
        console.log("image reordered");
        props.setColImageList(newImageList);
        // props.setImageChange(true);
    }
    return (
        <div className="tabContentMain">
            <div className="modalBodySort">
                <SortableGallery items={items} onSortEnd={onSortEnd} axis={"xy"} />
            </div>
            {/* <div className="modalBtnGrp">
                        <Button variant="danger" className="buttonGrp" onClick={handleClose}>Discard</Button>
                        <Button variant="primary" className="buttonGrp" onClick={saveReorder} >Save</Button>
                    </div>

                    
            <Button variant="primary" className="buttonGrp" onClick={() => setShow(true)}>Reorder</Button>
            <Modal show={show} size="xl" onHide={() => setShow(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    [Reorder] {props.header}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="modalBodySort">
                    <SortableGallery items={items} onSortEnd={onSortEnd} axis={"xy"} />
                    </div>
                    <div className="modalBtnGrp">
                        <Button variant="danger" className="buttonGrp" onClick={handleClose}>Discard</Button>
                        <Button variant="primary" className="buttonGrp" onClick={saveReorder} >Save</Button>
                    </div>
                </Modal.Body>
            </Modal> */}
        </div>
    );
}


export default Reorder;