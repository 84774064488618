import React, { Component } from 'react';
import './EditProjects.css';
import Auth from '../Auth';
import Layout from '../../../hoc/Layout/Layout';
import notie from "notie";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTable, faStoreAlt, faSortAmountDownAlt, faRedoAlt } from '@fortawesome/free-solid-svg-icons'

import { Card, Accordion, Button } from 'react-bootstrap';
import axios from 'axios';

import OtherProjects from '../../../components/UI/ProjectCard/OtherProjects';


import ProjectCardEdit from '../../../components/UI/ProjectCard/ProjectCardEdit';
import ProjectCardAddNew from '../../../components/UI/ProjectCard/ProjectCardAddNew';


import ReSortModal from '../../../components/UI/Modal/ReSortModal';

class EditProjects extends Component {

    constructor(props) {
        super(props);
        this.alertElement = React.createRef();
        this.state = {
            showModal: false,
            projectsWithImg: [],
            projectInTable: [],
            accTittle: [],
            accBgColor: [],
            accBgTableProject: ""

        }
    }

    componentDidMount() {
        // console.log('TESTING');
        axios.get('/api/getContent')
            .then(response => {
                // console.log(response.data);
                let projectsWithImgTemp = response.data.projectsWithImg;
                let projectInTableTemp = response.data.projectsInTable;
                // this.setState({
                //     projectsWithImg: response.data.projectsWithImg,
                //     projectInTable: response.data.projectsInTable
                // })

                let accBgColorList = {}
                let accTittleList = {}
                for (let j = 0; j < projectsWithImgTemp.length; j++) {
                    if (projectsWithImgTemp[j].visible === "true") {
                        accBgColorList[projectsWithImgTemp[j].keyId] = "AccColorNormal";
                    } else {
                        accBgColorList[projectsWithImgTemp[j].keyId] = "AccColorNotVisible";
                    }


                    accTittleList[projectsWithImgTemp[j].keyId] = projectsWithImgTemp[j].heading;
                }

                this.setState({
                    projectsWithImg: projectsWithImgTemp,
                    projectInTable: projectInTableTemp,
                    accBgColor: accBgColorList,
                    accTittle: accTittleList,
                    accBgTableProject: "AccColorNormalTable"
                })
            })
    }

    onSubmitLogout = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        Auth.setAuthenticated(false);
        Auth.logout(
            this.handleLogoutResponse,
            this.handleLogoutError);
    }
    handleLogoutResponse = (response) => {
        if (response.data && response.status === 200) {
            this.props.history.push("/login");
        }
    };

    handleLogoutError = (err) => {
        this.props.history.push("/login");
    };

    setBgColor = (projets) => {
        this.setState({
            accBgColor: projets
        })
    };

    setTitle = (projets) => {
        this.setState({
            accTittle: projets
        })
    };

    updateTittle = (keyId, tittle) => {
        var items = { ...this.state.accTittle };
        items[keyId] = tittle;
        this.setState({
            accTittle: items
        })
    }

    changesFound = (keyId, bgClass) => {
        // console.log("changesFound: "+ keyId + bgClass)
        var items = { ...this.state.accBgColor };
        // console.log("before: "+ items[keyId]);

        items[keyId] = bgClass;
        // console.log("after: "+ items[keyId]);
        this.setState({
            accBgColor: items
        })
    }

    // changesFoundTableProject = (bgClass) => {
    //     this.setState({
    //         accBgTableProject: bgClass
    //     })
    // }
    setProjectInTable = (projets) => {
        this.setState({
            projectInTable: projets
        })
    }
    setProjects = (projets) => {
        this.setState({
            projectsWithImg: projets
        })
    }
    deleteProject = (event) => {
        var self = this;
        event.preventDefault();
        event.stopPropagation();
        var projectsList = [...self.state.projectsWithImg];
        var classNames = event.target.className.split(" ");
        var delete_seq = 0;
        for (var i = 0; i < classNames.length; i++) {
            if (classNames[i].toUpperCase().indexOf("DELETE_") > -1) {
                delete_seq = parseInt(classNames[i].split('_')[1]);
            }
        }

        notie.confirm({
            text: 'Are you sure you wish to delete this project?',
            cancelCallback: function () {
                // notie.alert({ type: 3, text: 'Aw, why not? :(', time: 2 })
            },
            submitCallback: function () {
                // callDeleteMain();
                self.postProject(delete_seq, projectsList);
                notie.alert({ type: 1, text: 'Deleted Successfully', time: 2 })
            }
        });
        // const callDeleteMain = () => {
        //     this.postProject(delete_seq, projectsList);
        // }
    }

    postProject = async (seq_id, projectsList) => {
        var self = this;
        const sendingJson = {
            delete_seq: seq_id
        }
        await axios.post('/api/deleteProject', {
            sendingJson,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.data && response.status === 200) {
                for (var i in projectsList) {
                    if (seq_id === parseInt(projectsList[i].keyId)) {
                        projectsList.splice(i, 1);
                        break;
                    }
                }
                // console.log(JSON.stringify(projectsList));
                self.setProjects(projectsList);
                // console.log(JSON.stringify(self.state.projectsWithImg));
            }
        }).catch(function (err) {
            // console.log(err);
            notie.alert({ type: 'error', text: err, time: 2 });
        });
    }

    newProject = {
        "keyId": "9999",
        "sequence": "",
        "heading": "",
        "description": "",
        "client": "",
        "mainContractor:":"",
        "scope": "",
        "visible": "true",
        "colImageList": [
        ]
    }

    addNewcolImageList = []



    reSequence = (event) => {
        event.preventDefault();
        event.stopPropagation();
        var hasChange = false;

        Object.entries(this.state.accBgColor).map(([key, value]) => {
            if (value === 'AccColorDanger') {
                hasChange = true;
            }
        })
        console.log(hasChange);
        if (hasChange) {
            notie.alert({ type: 'warning', text: 'Please <q>Save</q> Unsaved Changes or <q>Discard All Changes</q>', time: 5 });
        } else {
            this.setModalShow(true);
        }
    }



    discardAll = (event) => {
        event.preventDefault();
        event.stopPropagation();
        window.location.reload();
    }

    setModalShow = (show) => {
        this.setState({ showModal: show });
    }

    reSortOnClick = async (projects) => {
        await axios.post('/api/saveReordedProject', {
          projects,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(this.successSavingSort).catch(this.errorSavingSort);
      }
    
      successSavingSort = (response) => {
        if (response.data && response.status === 200) {
          window.location.reload();
          //notie.alert({ type: 'success', text: 'Project Details Saved Successfully.', time: 2 });
        }
      };
      errorSavingSort = (err) => {
        console.log(JSON.stringify(err));
        notie.alert({ type: 'error', text: "Failed to update. Please try again.", time: 2 });
      };

    render() {
        // console.log("test")
        // console.log("test"+ JSON.stringify(this.state.projectsWithImg));
        return (
            <Layout role="admin" logOutMe={this.onSubmitLogout}>
                <div className="editAdminMain">

                    <Accordion >
                        <Card key={1000000}>
                            <Accordion.Toggle as={Card.Header}
                                className="AccColorHeading">
                                <FontAwesomeIcon icon={faStoreAlt} size="1x" className="faplus" />
                                <b>SANYO'S PROJECTS</b>

                                <Button key="reSequenceid" variant="info"
                                    className={"reSequenceClass"}
                                    onClick={this.reSequence}>
                                    <FontAwesomeIcon icon={faSortAmountDownAlt} size="1x" />
                                        &nbsp; Rearrange Projects</Button>
                                <Button key="discardAllid" variant="danger"
                                    className={"reSequenceClass"}
                                    onClick={this.discardAll}>
                                    <FontAwesomeIcon icon={faRedoAlt} size="1x" />
                                        &nbsp; Discard All Changes</Button>
                            </Accordion.Toggle>
                        </Card>

                        {this.state.projectsWithImg.map((project, index) => (
                            <Card key={index}>
                                <Accordion.Toggle as={Card.Header} eventKey={project.keyId}
                                    className={this.state.accBgColor[project.keyId]}
                                >
                                    {this.state.accTittle[project.keyId]}


                                    <Button key={"delete" + index} variant="danger"
                                        className={"deleteProjectButton delete_" + project.keyId + ""}
                                        onClick={this.deleteProject}>Delete Project</Button>

                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={project.keyId}>
                                    <Card.Body>
                                        <ProjectCardEdit
                                            key={index}
                                            project={project}
                                            indexId={index}
                                            // sequence={project.sequence}
                                            // visible={project.visible}
                                            // header={project.heading}
                                            colImageList={project.colImageList}
                                            updateTittle={this.updateTittle}
                                            changesFound={this.changesFound}
                                            totalNumberOfProject={this.state.projectsWithImg.length}
                                        // description={project.description}
                                        // client={project.client}
                                        // scope={project.scope}
                                        // handleProjectsOnChange={this.handleProjectsOnChange}
                                        />

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}


                        <Card key={99999999}>
                            <Accordion.Toggle as={Card.Header}
                                className="AccColorAddNew"
                                eventKey={99999999}>
                                <FontAwesomeIcon icon={faPlus} size="1x" className="faplus" />
                                Add New Project
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={99999999}>
                                <Card.Body>
                                    <ProjectCardAddNew
                                        key={99999999}
                                        project={this.newProject}
                                        indexId={99999999}
                                        colImageList={this.addNewcolImageList}
                                        projectsWithImg={this.state.projectsWithImg}
                                        setProjects={this.setProjects}
                                        accBgColor={this.state.accBgColor}
                                        accTittle={this.state.accTittle}
                                        setBgColor={this.setBgColor}
                                        setTitle={this.setTitle}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card key={88888888}>
                            <Accordion.Toggle as={Card.Header} eventKey={88888888}
                                className={this.state.accBgTableProject}>
                                <FontAwesomeIcon icon={faTable} size="1x" className="faplus" />
                                MANAGE OTHER PROJECTS
                                </Accordion.Toggle>
                            <Accordion.Collapse eventKey={88888888}>
                                <Card.Body>
                                    <OtherProjects
                                        projectInTable={this.state.projectInTable}
                                        setProjectInTable={this.setProjectInTable}
                                        // changesFoundTableProject={this.changesFoundTableProject}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <div className="copyWriteAdmin">
                    Sanyo Engineering Pte Ltd. All Rights Reserved © 2020
                </div>
                </div>

                <ReSortModal
                    show={this.state.showModal}
                    projectswithimg={this.state.projectsWithImg}
                    reSortOnClick={this.reSortOnClick}
                    onHide={() => this.setModalShow(false)}
                />
            </Layout>
        );
    }
}

export default EditProjects;