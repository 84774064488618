
import React, { useCallback, useState } from 'react';
import Gallery from "react-photo-gallery";
import SelectedImage from "./SelectedImage";
import { Button, ButtonToolbar } from 'react-bootstrap';
import notie from "notie";

const DeleteImage = (props) => {
    const photos = props.colImageList.map((item, index) => {
        return {
            key: index.toString(),
            src: process.env.PUBLIC_URL + item,
            width: 1,
            height: 1
        };
    }
    );

    let toDeleteList = props.colImageList.map((item, index) => {
        return {
            keyId: props.keyId,
            src: item,
            isSelected: false
        };
    }
    );

    function showOnlySelected(list) {
        toDeleteList.filter(imagePath => list.src === imagePath.src)
            .map(imagePath => imagePath.isSelected = list.isSelected);
    }


    const [selectAll, setSelectAll] = useState(false);

    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
    };


    const imageRenderer = useCallback(
        ({ index, left, top, key, photo }) => (
            <SelectedImage
                selected={selectAll ? true : false}
                key={key}
                margin={"2px"}
                index={index}
                photo={photo}
                left={left}
                top={top}
                showOnlySelected={showOnlySelected}
                keyId={props.keyId}

            />
        ),
        [selectAll, showOnlySelected, props.keyId]
    );


    const delectFunction = () => {
        let finalDelete = [];
        toDeleteList.forEach(imagePath => {
            if (!imagePath.isSelected) {
                finalDelete.push(imagePath.src);
            }
        });

        // console.log(JSON.stringify(finalDelete));
        //TODO: 
        if (finalDelete.length === toDeleteList.length) {
            notie.alert({ type: 'success', text: 'No Image seleted to delete', time: 2 });
        } else {
            //let message = "Are you sure you wish to delete " + finalDelete.length + " photo.?"
            // if (window.confirm(message))
            //     console.log("delete now");
            // else
            //     console.log("Dont delete now");
            notie.alert({ type: 'success', text: 'Delete Successfull', time: 2 });
            props.setColImageList(finalDelete);
            

        }

    }

    const BasicRows = () => <Gallery photos={photos} renderImage={imageRenderer} />;
    return (
        <div className="tabContentMain">
            <div className="imageGallary">
                <div className="columnImg" >
                    <BasicRows />
                </div>
            </div>
            <ButtonToolbar className="uploadDiv">
                <Button variant="info" className="buttonGrp" onClick={toggleSelectAll}>Select all</Button>
                <Button variant="danger" className="buttonGrp" onClick={delectFunction}>Delete</Button>
            </ButtonToolbar>
        </div>
    )
};

export default DeleteImage;

