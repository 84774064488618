import React from 'react';

import { Container } from 'react-bootstrap';
import './NotFound.css'

import homeBg1 from '../../../assets/Images/404notFound.png';
import logoImage from '../../../assets/Images/footer/logoFooter.png';

const NotFound = (props) => {

    return (
       
        <div className="pimg404" style={{ backgroundImage: "url(" + homeBg1 + ")" }}>
            <Container>
                <div className="notdevelopedOut">
                    <div className="notdeveloped">
                        <h1 className="">This page isn't available. Sorry about that..</h1>
                        <h1 className="">Visit: 
                        <a href="/">
                        <img src={logoImage} alt="logo" className="notFoundLogo" />
                        Sanyo Engineering Pte Ltd
                        </a></h1>
                    </div>
                </div>
            </Container>
        </div>
    );
}


export default NotFound;