import React, {  useState, useEffect } from 'react';
import { Button, Modal, ListGroup } from 'react-bootstrap';
import { ReactSortable } from "react-sortablejs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAlt, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import notie from "notie";
import axios from 'axios';

const ReSortModal = (props) => {
  const [projects, setProjects] = useState(props.projectswithimg);

  useEffect(() => {
    setProjects(props.projectswithimg);
  }, [props])

  

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      // dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      className="reSortmodalCss"
    >
      <Modal.Header className="RearrangeProjectsHeading">
        <Modal.Title id="RearrangeProjects">
          <FontAwesomeIcon icon={faSortAmountDownAlt} size="1x" />
          <b>&nbsp; Rearrange</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactSortable
          list={projects}
          setList={newState => setProjects(newState)}>
          {projects.map(item => (
            <ListGroup key={item.keyId}>
              <ListGroup.Item key={item.keyId} className="reorderListItem" >
                {item.heading}
                <FontAwesomeIcon icon={faArrowsAlt} className="deleteProjectButton" />
              </ListGroup.Item>
            </ListGroup>
          ))}
        </ReactSortable>
      </Modal.Body>

      <Modal.Footer >
        
        <Button onClick={() => props.reSortOnClick(projects)}
        >Rearrange</Button>
        <Button variant="danger" onClick={props.onHide}>Discard</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReSortModal;

