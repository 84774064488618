import React, { useState, useEffect } from 'react';
import './projectCard.css';
import './notie.css';
import { Button, Tabs, Tab } from 'react-bootstrap';
import DeleteImage from './DeleteImage';
import Reorder from './Reorder';
import EditDetails from './EditDetails';
import AddNewImage from './AddNewImage';
import notie from "notie";
import axios from 'axios';

let keyIdChanges = false;

// let counter = 0;
const ProjectCardEdit = (props) => {
    // counter = 0;
    let imgFolderPath = '/admin/project/cal/';
    const [stateCount , setStateCount]=useState(0);
    // const [imageChange , setImageChange]=useState(false);

    // console.log("Counter: "+ stateCount);
    const [inputValues, setInputValues] = useState({
        keyId: props.project.keyId,
        sequence: props.project.sequence,
        visible: props.project.visible,
        heading: props.project.heading,
        description: props.project.description,
        client: props.project.client,
        mainContractor:props.project.mainContractor,
        scope: props.project.scope
    });
    const [colImageList, setColImageList] = useState(props.colImageList);
    const [buttonColorClass, setButtonColorClass] = useState("primary saveButton");
    const handleOnChange = event => {
        keyIdChanges = true;
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };
    const saveButton = async e => {
        e.preventDefault();
        if (inputValues.heading && inputValues.description && inputValues.scope && inputValues.client && inputValues.mainContractor )
            postProject(inputValues, colImageList, successSaving, errorSaving);
        else
            notie.alert({ type: 'warning', text: 'Please Fill in all details', time: 2 });
    }
    const postProject = async (inputValues, colImageList, successSaving, errorSaving) => {
        const sendingJson = {
            ...inputValues,
            colImageList: colImageList
        }
        await axios.post('/api/saveProject', {
            sendingJson,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(successSaving).catch(errorSaving);
    }
    const successSaving = (response) => {
        if (response.data && response.status === 200) {
            props.updateTittle(inputValues.keyId, inputValues.heading);
            if (inputValues.visible === "true") {
                props.changesFound(inputValues.keyId, "AccColorNormal");
            } else {
                props.changesFound(inputValues.keyId, "AccColorNotVisible");
            }
            setButtonColorClass("primary saveButton");
            keyIdChanges=false;
            notie.alert({ type: 'success', text: 'Project Details Saved Successfully.', time: 2 });
        }
    };
    const errorSaving = (err) => {
        console.log(JSON.stringify(err));
        notie.alert({ type: 'error', text: JSON.stringify(err), time: 2 });
    };

    // useEffect(() => {
    //    console.log("useEffect on props:" + counter);
    // }, [props])

    useEffect(() => {
        setInputValues({
            keyId: props.project.keyId,
            sequence: props.project.sequence,
            visible: props.project.visible,
            heading: props.project.heading,
            description: props.project.description,
            client: props.project.client,
            mainContractor:props.project.mainContractor,
            scope: props.project.scope
        })
    }, [props.project])

    useEffect(() => {
        // console.log("useEffect on props.colImageList:" );
        setStateCount(0);
        setColImageList(props.colImageList);
    }, [props.colImageList])

    useEffect(() => {
        // console.log("useEffect on inputValues: keyIdChanges:" + keyIdChanges);
        if (keyIdChanges) {
            if (buttonColorClass === "primary saveButton") {
                setButtonColorClass("danger saveButton");
                props.changesFound(inputValues.keyId, "AccColorDanger");
               console.log(inputValues.keyId + "inputValues change found...");
            }
        }
    }, [inputValues])

    useEffect(() => {
        setStateCount(stateCount + 1);
        // console.log("useEffect on : colImageList:" + stateCount + "->" + props.totalNumberOfProject);
        if (parseInt(stateCount) > 0) {
            if (buttonColorClass === "primary saveButton") {
                setButtonColorClass("danger saveButton");
                props.changesFound(inputValues.keyId, "AccColorDanger");
            //    console.log(inputValues.keyId + "colImageList change found...");
            }
            //counter=props.totalNumberOfProject;
        }
    }, [colImageList])

    return (
        <div>
            <div className="projectCardEditMainClass">
                <Tabs justify id="uncontrolled-tab-example" defaultActiveKey="Details">
                    <Tab eventKey="Details" title="Details">
                        <EditDetails
                            key={props.index}
                            keyId={inputValues.keyId}
                            sequence={inputValues.sequence}
                            visible={inputValues.visible}
                            heading={inputValues.heading}
                            description={inputValues.description}
                            client={inputValues.client}
                            mainContractor={inputValues.mainContractor}
                            scope={inputValues.scope}
                            handleProjectsOnChange={handleOnChange}
                        />
                    </Tab>
                    <Tab eventKey="Reorder" title="Reorder">
                        <Reorder colImageList={colImageList} setColImageList={setColImageList} 
                        // setImageChange={setImageChange}
                        />
                    </Tab>
                    <Tab eventKey="Delete" title="Delete">
                        <DeleteImage colImageList={colImageList} keyId={inputValues.keyId}
                            setColImageList={setColImageList} />
                    </Tab>
                    <Tab eventKey="Upload" title="Upload">
                        <AddNewImage colImageList={colImageList} setColImageList={setColImageList}
                            imgFolderPath={imgFolderPath}
                            keyId={inputValues.keyId} numberOfPhoto={colImageList.length} />
                    </Tab>
                </Tabs>
                <Button key={props.index}
                    disabled={buttonColorClass.toUpperCase().indexOf("PRIMARY") > -1}
                    variant={buttonColorClass} onClick={saveButton} >Save changes</Button>
            </div>
        </div>
    );
}

export default ProjectCardEdit;