import React from 'react';
// import Switch from "react-switch";
import ToggleSwitch from './ToggleSwitch';
import {  Col, Form, Row } from 'react-bootstrap';

const EditDetails = (props) => {
    // const [project, setProject] = useState(props.project);


    return (
        <div className="tabContentMain">
            <br></br>
            {/* <Form.Group as={Row} controlId={props.keyId+"sequence"}>
                <Form.Label column sm={4}>
                    Sequence:
                                </Form.Label>
                <Col sm={8}>
                    <Form.Control type="text" placeholder="Sequence"
                        value={props.sequence}  
                        type='number'
                        name="sequence"
                        required
                        onChange={props.handleProjectsOnChange}/>
                </Col>
            </Form.Group> */}
            <Form.Group as={Row} controlId={props.keyId+"heading"}>
                <Form.Label column sm={4}>
                    Heading:
                                </Form.Label>
                <Col sm={8}>
                    <Form.Control type="text" placeholder="header"
                     name="heading"
                        value={props.heading} 
                        required
                        onChange={props.handleProjectsOnChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={props.keyId+"description"}>
                <Form.Label column sm={4}>
                    Description:
                                </Form.Label>
                <Col sm={8}>
                    <Form.Control as="textarea" rows="3" placeholder="Description"
                     name="description"
                        value={props.description} 
                        required
                        onChange={props.handleProjectsOnChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={props.keyId+"client"}>
                <Form.Label column sm={4}>
                    Client:
                                </Form.Label>
                <Col sm={8}>
                    <Form.Control as="textarea" rows="1" placeholder="Client"
                     name="client"
                        value={props.client} 
                        required
                        onChange={props.handleProjectsOnChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={props.keyId+"mainContractor"}>
                <Form.Label column sm={4}>
                Main Contractor:
                                </Form.Label>
                <Col sm={8}>
                    <Form.Control as="textarea" rows="1" placeholder="Main Contractor"
                     name="mainContractor"
                        value={props.mainContractor} 
                        required
                        onChange={props.handleProjectsOnChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={props.keyId+"scope"}>
                <Form.Label column sm={4}>
                    Scope:
                                </Form.Label>
                <Col sm={8}>
                    <Form.Control as="textarea" rows="2" placeholder="scope"
                     name="scope"
                        value={props.scope} 
                        required
                        onChange={props.handleProjectsOnChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={props.keyId+"visible"}>
                <Form.Label column sm={4}>
                    Visible:
                                </Form.Label>
                <Col sm={8} className="toggleLeft">
                    <ToggleSwitch visible={props.visible} handleProjectsOnChange={props.handleProjectsOnChange}/>
                    {/* <Form.Control required type="text" placeholder="Visible"
                     name="visible"
                        value={props.visible} onChange={props.handleProjectsOnChange} /> */}
                </Col>
            </Form.Group>
        </div>
    );
}


export default EditDetails;