import React, { Component } from 'react';

import Aux from '../_Aux/_Aux';
import   './Layout.css';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';

import Auth from '../../containers/Admin/Auth';
import Footer from './Footer';


class Layout extends Component { 
    
    constructor(props) {
        super(props);
        this.state = {
            showSideDrawer: false,
            closedByUser: false
        };
      }

    

    sideDrawerClosedHandler = () => {
        this.setState( { showSideDrawer: false,  closedByUser: true} );
    }

    sideDrawerToggleHandler = () => {
        this.setState( ( prevState ) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        } );
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        Auth.setAuthenticated(false);
        Auth.logout(
            this.handleLogoutResponse,
            this.handleLogoutError);
    }
    handleLogoutResponse = (response) => {
        if (response.data && response.status === 200) {
            this.props.history.push("/login");
        }
    };

    handleLogoutError = (err) => {
        this.props.history.push("/login");
    };

    render () {
        return (
            <Aux>
                
                <Toolbar drawerToggleClicked={this.sideDrawerToggleHandler}   
                role={this.props.role} 
                logOutMe={this.props.logOutMe}
                />
                <SideDrawer
                    open={this.state.showSideDrawer}
                    closed={this.sideDrawerClosedHandler} 
                    userAction={this.state.closedByUser}
                    role={this.props.role}/>
                <main>
                    {this.props.children}
                </main>
                {this.props.role==="public"?<Footer/>:<></>}
                
            </Aux>
        )
    }
}

export default Layout;