import React, { Component } from 'react';
import './EditClients.css';
import Auth from '../Auth';
import Layout from '../../../hoc/Layout/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTable, faStoreAlt, faSortAmountDownAlt, faRedoAlt, faFile, faFileAlt, faFileContract, faHandshake, faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import { Card, Accordion, Button } from 'react-bootstrap';
import axios from 'axios';
import ClientCardEdit from '../../../components/UI/ProjectCard/ClientCardEdit';
import ClientCardAddNew from '../../../components/UI/ProjectCard/ClientCardAddNew';
import notie from "notie";
import ReSortModal from '../../../components/UI/Modal/ReSortModal';

class EditClients extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            clientList: [],
            accTittle: [],
            accBgColor: []
        }
    }


    componentDidMount() {
        // console.log('TESTING');
        axios.get('/api/getContent')
            .then(response => {
                
                let clientListTemp = response.data.clients;
                console.log(clientListTemp);
                let accBgColorList = {}
                let accTittleList = {}
                for (let j = 0; j < clientListTemp.length; j++) {
                    if (clientListTemp[j].visible === "true") {
                        accBgColorList[clientListTemp[j].keyId] = "AccColorNormal";
                    } else {
                        accBgColorList[clientListTemp[j].keyId] = "AccColorNotVisible";
                    }


                    accTittleList[clientListTemp[j].keyId] = clientListTemp[j].heading;
                }

                this.setState({
                    clientList: clientListTemp,
                    accBgColor: accBgColorList,
                    accTittle: accTittleList
                })
            })
    }

    onSubmitLogout = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        Auth.setAuthenticated(false);
        Auth.logout(
            this.handleLogoutResponse,
            this.handleLogoutError);
    }
    handleLogoutResponse = (response) => {
        if (response.data && response.status === 200) {
            this.props.history.push("/login");
        }
    };

    handleLogoutError = (err) => {
        this.props.history.push("/login");
    };
    changesFound = (keyId, bgClass) => {
        // console.log("changesFound: "+ keyId + bgClass)
        var items = { ...this.state.accBgColor };
        // console.log("before: "+ items[keyId]);

        items[keyId] = bgClass;
        // console.log("after: "+ items[keyId]);
        this.setState({
            accBgColor: items
        })
    }
    updateTittle = (keyId, tittle) => {
        var items = { ...this.state.accTittle };
        items[keyId] = tittle;
        this.setState({
            accTittle: items
        })
    }
    newClient = {
        "keyId": "9999",
        "sequence": "",
        "heading": "",
        "visible": "true",
        "colImageList": [
        ]
    };
    addNewcolImageList = [];
    setClient = (client) => {
        this.setState({
            clientList: client
        })
    };

    setBgColor = (client) => {
        this.setState({
            accBgColor: client
        })
    };
    setTitle = (client) => {
        this.setState({
            accTittle: client
        })
    };
    discardAll = (event) => {
        event.preventDefault();
        event.stopPropagation();
        window.location.reload();
    }
    deleteProject = (event) => {
        var self = this;
        event.preventDefault();
        event.stopPropagation();
        var projectsList = [...self.state.clientList];
        var classNames = event.target.className.split(" ");
        var delete_seq = 0;
        for (var i = 0; i < classNames.length; i++) {
            if (classNames[i].toUpperCase().indexOf("DELETE_") > -1) {
                delete_seq = parseInt(classNames[i].split('_')[1]);
            }
        }

        notie.confirm({
            text: 'Are you sure you wish to delete this project?',
            cancelCallback: function () {
                // notie.alert({ type: 3, text: 'Aw, why not? :(', time: 2 })
            },
            submitCallback: function () {
                // callDeleteMain();
                self.postProject(delete_seq, projectsList);
                notie.alert({ type: 1, text: 'Deleted Successfully', time: 2 })
            }
        });
        // const callDeleteMain = () => {
        //     this.postProject(delete_seq, projectsList);
        // }
    }

    postProject = async (seq_id, projectsList) => {
        var self = this;
        const sendingJson = {
            delete_seq: seq_id
        }
        console.log(sendingJson);
        await axios.post('/api/deleteClient', {
            sendingJson,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.data && response.status === 200) {
                for (var i in projectsList) {
                    if (seq_id === parseInt(projectsList[i].keyId)) {
                        projectsList.splice(i, 1);
                        break;
                    }
                }
                // console.log(JSON.stringify(projectsList));
                self.setClient(projectsList);
                // console.log(JSON.stringify(self.state.projectsWithImg));
            }
        }).catch(function (err) {
            // console.log(err);
            notie.alert({ type: 'error', text: err, time: 2 });
        });
    }

    reSequence = (event) => {
        event.preventDefault();
        event.stopPropagation();
        var hasChange = false;

        Object.entries(this.state.accBgColor).map(([key, value]) => {
            if (value === 'AccColorDanger') {
                hasChange = true;
            }
        })
        console.log(hasChange);
        if (hasChange) {
            notie.alert({ type: 'warning', text: 'Please <q>Save</q> Unsaved Changes or <q>Discard All Changes</q>', time: 5 });
        } else {
            this.setModalShow(true);
        }
    }
    setModalShow = (show) => {
        this.setState({ showModal: show });
    }

    reSortOnClick = async (projects) => {
        await axios.post('/api/saveReordedClient', {
          projects,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(this.successSavingSort).catch(this.errorSavingSort);
      }
    
      successSavingSort = (response) => {
        if (response.data && response.status === 200) {
          window.location.reload();
          //notie.alert({ type: 'success', text: 'Project Details Saved Successfully.', time: 2 });
        }
      };
      errorSavingSort = (err) => {
        console.log(JSON.stringify(err));
        notie.alert({ type: 'error', text: "Failed to update. Please try again.", time: 2 });
      };



    render() {
        return (
            <Layout role="admin" logOutMe={this.onSubmitLogout}>
                <div className="editAdminMain">
                <Accordion >
                <Card key={1000000}>
                            <Accordion.Toggle as={Card.Header}
                                className="AccColorHeading">
                                <FontAwesomeIcon icon={faHandsHelping} size="1x" className="faplus" />
                                <b>SANYO'S CLIENTS</b>

                                <Button key="reSequenceid" variant="info"
                                    className={"reSequenceClass"}
                                    onClick={this.reSequence}>
                                    <FontAwesomeIcon icon={faSortAmountDownAlt} size="1x" />
                                        &nbsp; Rearrange Clients</Button>
                                <Button key="discardAllid" variant="danger"
                                    className={"reSequenceClass"}
                                    onClick={this.discardAll}>
                                    <FontAwesomeIcon icon={faRedoAlt} size="1x" />
                                        &nbsp; Discard All Changes</Button>
                            </Accordion.Toggle>
                        </Card>
                        {this.state.clientList.map((client, index) => (
                            <Card key={index}>
                                <Accordion.Toggle as={Card.Header} eventKey={client.keyId}
                                    className={this.state.accBgColor[client.keyId]}
                                >
                                    {this.state.accTittle[client.keyId]}


                                    <Button key={"delete" + index} variant="danger"
                                        className={"deleteProjectButton delete_" + client.keyId + ""}
                                        onClick={this.deleteProject}>Delete Client</Button>

                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={client.keyId}>
                                    <Card.Body>
                                        <ClientCardEdit
                                            key={index}
                                            client={client}
                                            indexId={index}
                                            colImageList={client.colImageList}
                                            updateTittle={this.updateTittle}
                                            changesFound={this.changesFound}
                                        />

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}

                        <Card key={99999999}>
                            <Accordion.Toggle as={Card.Header}
                                className="AccColorAddNew"
                                eventKey={99999999}>
                                <FontAwesomeIcon icon={faPlus} size="1x" className="faplus" />
                                Add New Client
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={99999999}>
                                <Card.Body>
                                    <ClientCardAddNew
                                        key={99999999}
                                        newClient={this.newClient}
                                        indexId={99999999}
                                        colImageList={this.addNewcolImageList}
                                        clientList={this.state.clientList}
                                        setClient={this.setClient}
                                        accBgColor={this.state.accBgColor}
                                        accTittle={this.state.accTittle}
                                        setBgColor={this.setBgColor}
                                        setTitle={this.setTitle}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                </Accordion>
                <div className="copyWriteAdmin">
                    Sanyo Engineering Pte Ltd. All Rights Reserved © 2020
                </div>
                </div>
                <ReSortModal
                    show={this.state.showModal}
                    projectswithimg={this.state.clientList}
                    reSortOnClick={this.reSortOnClick}
                    onHide={() => this.setModalShow(false)}
                />
            </Layout>


        );
    }
}





export default EditClients;