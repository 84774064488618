import React, { Component } from 'react';

import './About.css';
import aboutBg1 from '../../assets/Images/about/1.png';
import BasicDesign from '../../components/UI/BasicDesign/BasicDesign';
import aboutBg2 from '../../assets/Images/about/2.png';
import aboutBg3 from '../../assets/Images/about/3.png';
import aboutBg4 from '../../assets/Images/about/4.png';
import aboutBg5 from '../../assets/Images/about/5.png';
import aboutBg6 from '../../assets/Images/about/6.png';
import aboutBg7 from '../../assets/Images/about/7.png';
// import aboutBg8 from '../../assets/Images/about/8.png';

import Modal from '../../components/UI/Modal/Modal';
import LearnMore from '../../components/LearnMore/LearnMore';
// import {Card,CardDeck} from 'react-bootstrap';

// import man from '../.././assets/Images/about/man.jpg';

import Layout from '../../hoc/Layout/Layout';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

let learnMore = null;
let currentScrollPosAbout = 0;

class About extends Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.plantCivikWorks = React.createRef();
    this.scaffoldingWorks = React.createRef();
    this.insulationWorks = React.createRef();
    this.manPowerProvision = React.createRef();
    this.blastingWorks = React.createRef();
    this.maintenancePlant = React.createRef();


    this.state = {
      showModal: false,
      detailsObj: []
    }

    
  }



  componentDidUpdate(){
    
    this.scrollToMyRef(this.props.location.scrollRef);
    
  }

  scrollToMyRef = (towhere) => {
    if (towhere) {
      if (towhere === 'plantCivikWorks') {
        window.scrollTo({
          top: this.plantCivikWorks.current.offsetTop,
          behavior: 'smooth'
        })
      } else if (towhere === 'scaffoldingWorks') {
        window.scrollTo({
          top: this.scaffoldingWorks.current.offsetTop,
          behavior: 'smooth'
        })
      } else if (towhere === 'insulationWorks') {
        window.scrollTo({
          top: this.insulationWorks.current.offsetTop,
          behavior: 'smooth'
        })
      } else if (towhere === 'manPowerProvision') {
        window.scrollTo({
          top: this.manPowerProvision.current.offsetTop,
          behavior: 'smooth'
        })
      } else if (towhere === 'blastingWorks') {
        window.scrollTo({
          top: this.blastingWorks.current.offsetTop,
          behavior: 'smooth'
        })
      } else if (towhere === 'maintenancePlant') {
        window.scrollTo({
          top: this.maintenancePlant.current.offsetTop,
          behavior: 'smooth'
        })
      }else if (towhere === 'arrow') {
        window.scrollTo({
          top: this.myRef.current.offsetTop,
          behavior: 'smooth'
        })
      }
    }
  }

  componentDidMount() {
    this.scrollToMyRef(this.props.location.scrollRef);
  }
  

  showMoreDetails = (detailsObj, id) => {

    this.setState({
      showModal: true
    });



    learnMore = <LearnMore
      heading={detailsObj[id].header}
      modalList={detailsObj[id].modalList}
      content={detailsObj[id].content}
      closeModal={this.closeShowMore} />;

    var startScroll = document.getElementsByClassName("AboutMain")[0];
    if (startScroll) {
      currentScrollPosAbout = window.pageYOffset;
      startScroll.classList.add("no-body-scroll");
    }

  }

  closeShowMore = () => {
    var stopScroll = document.getElementsByClassName("AboutMain")[0];
    if (stopScroll) {
      stopScroll.classList.remove("no-body-scroll");
      window.scroll(0, currentScrollPosAbout);
    }
    this.setState({ showModal: false });
  }

  render() {

    return (
      <div className="AboutMain" >
        <Layout role="public">

          <div className="pimg" style={{ backgroundImage: "url(" + aboutBg1 + ")" }}>
            <div className="ptext">
              <span className="borders">
                MAINTANCE & PROJECT PLANTS
              </span>
            </div>
          </div>
          <section ref={this.myRef} className="section section-light-about">
            <h2 className="my-header-main">WHAT WE DO</h2>
            <div id="whatWeDo">
              <ScrollAnimation animateIn="slideInLeft" offset={50}>
                <div className="ptext-aboutMessage">
                  Sanyo Engineering Pte Ltd was establish in Feb 2014, as a young,
                  dynamic contractor specializing in the Integrated Civil Engineering,
                  Project Management and M&E Works. We have been constantly striving hard
                  to greater heights through hard work, innovation, value engineering and
                  sustainable progressive growth providing responsive and quality services to our clients.
            </div>
              </ScrollAnimation>
            </div>
            <h2 ref={this.plantCivikWorks} className="my-header-sub">PLANT CIVIL WORK</h2>
          </section>
          <BasicDesign
            bg={aboutBg2}
            side={0}
            header="PLANT CIVIL WORK"
            id={1}
            animation={0}
            subHeading="SAFETY WORKS"
            content={<div><p>Sanyo offers engineering, construction and project management services for projects of all size and complexity in the civil sector. Our civil construction works cover the processing industries, refinery and chemical plant environment.</p>
          <p>From minor projects such as excavation or replacement and repair to drainage systems, walkway or fencing installation, to more extensive work including structural renovation, tunnelling enabling works, structural and architectural steelwork.</p>
          <p>
We have a significant and longstanding experience of delivering multi-asset works packages within the rail and other industries, understanding the requirement to fulfil a complete mobilisation and rollout programme with the need to allocate the sufficient level of resource and competence in compliance with your specifications, regulatory conditions and legislative frameworks.</p></div>}

            modalList={[{ id: 1, value: "Construction of Roads" },
            { id: 2, value: "Widening, Extension, Re-marking and Maintenance of Roads" },
            { id: 3, value: "Construction of Walkways" },
            { id: 4, value: "Construction & Maintenance of Road Drainage" },
            { id: 5, value: "Equipment foundation" },
            { id: 6, value: "Cable Trench excavation and backfilling" }]}
            modalShow={this.showMoreDetails} />

          <section ref={this.blastingWorks}>
            <BasicDesign
              bg={aboutBg6}
              side={2}
              header="BLASTING / PAINTING WORKS"
              id={5}
              animation={1}
              subHeading="GOOD QUALITY FINISH"
              content="Sanyo have well equipped to handle onsite applications with blasting equipment for the purpose of preparing surfaces such as tank bottom plates / Shell plate.All our processes are carried out and driven by careful calibration and quality control. Our expertise in painting is wide ranging and our commitment to safety and environmental needs. All work is performed by experienced personnel accustomed to working with demanding deadlines, complex coatings and lining system in difficult and hostile environments. We are dedicated to Quality, Safety and Time Management. We assure that the client gets the quality they deserve."
              modalList={[{ id: 1, value: "On Site Painting Services" },
              { id: 2, value: "On Site Blasting Services" },
              { id: 3, value: "Power Brush & Roller Painting" },
              { id: 4, value: "Hydro Jet Washing" }]}
              modalShow={this.showMoreDetails} />
          </section>

          <section ref={this.scaffoldingWorks}>
            <BasicDesign
              bg={aboutBg3}
              side={1}
              header="SCAFFOLDING WORKS"
              id={2}
              animation={0}
              subHeading="BUILT WITH GOOD SUPPORT"
              content="Sanyo is an Approved Scaffold Contractor certified by the Ministry of Manpower, All our scaffolds are constructed by certified scaffolders under the supervision of our qualified scaffold supervisors and where necessary, certified by our professional engineers to be safe for use in accordance with Singapore Standard, Code of Practice 14: 1996. Also provided quality scaffold manpower supply, subcontract works."
              modalList={[{ id: 1, value: "Erection and Dismantling of Scaffolds" },
              { id: 2, value: "Supply qualified scaffold supervisors and erectors" }]}
              modalShow={this.showMoreDetails} />
          </section>
          <section ref={this.insulationWorks}>
            <BasicDesign
              bg={aboutBg4}
              side={2}
              header="INSULATION WORKS"
              id={3}
              animation={1}
              subHeading="ATTENTION TO DETAILS"
              content="Sanyo Engineering Pte Ltd is committed to getting the job done, especially when it comes to this service. You can count on us to be professional,
          timely, efficient and make sure you’re satisfied every step of the way."
              modalList={[{ id: 1, value: "Hot and Cold Insulation Services" },
              { id: 2, value: "Supply of Insulation Materials" },
              { id: 3, value: "Installation of Insulation for pipes and equipment’s in Process & Construction Industry" }]}
              modalShow={this.showMoreDetails} />
          </section>

          <section ref={this.maintenancePlant}>
            <BasicDesign
              bg={aboutBg7}
              side={1}
              header="OTHER SPECIALIZATION"
              id={6}
              animation={0}
              subHeading="MAINTENANCE PROCESS PLANT"
              content="We have the experience and skills necessary to tackle just about every type of job that comes 
          our way. With Sanyo Engineering Pte Ltd, clients know exactly what to expect - 
          professionalism, efficiency and exceptional results."
              buttonName="OTHER WORKS"
              modalList={[{ id: 1, value: "Mechanical Works" },
              { id: 2, value: "Steel Structural Works" },
              { id: 3, value: "Concrete Repair Works" },
              { id: 4, value: "Coring Works" },
              { id: 5, value: "Demolition Works" },
              { id: 6, value: "Road Works" },
              { id: 7, value: "Excavation of Ground" },
              { id: 8, value: "Backfilling of Soil/Sand" }]}
              modalShow={this.showMoreDetails} />
          </section>

          <section ref={this.manPowerProvision}>
            <BasicDesign
              bg={aboutBg5}
              side={1}
              header="MANPOWER PROVISION"
              id={4}
              animation={0}
              subHeading="SKILLED AND EXPERIENCE"
              content="Sanyo is specializing in providing professionals, skilled/semi-skilled tradesmen for the Process Maintenance & Construction, Building Construction, Oil and Gas Industry. We have the experience and skills necessary to tackle just about every type of job that comes our way. With Sanyo Engineering Pte Ltd, clients know exactly what to expect - professionalism, efficiency and exceptional results"
              modalList={[{ id: 1, value: "Provides professionals" },
              { id: 2, value: "skilled / semi-skilled tradesmen for the Process Maintenance & Construction" },
              { id: 3, value: "Building Construction" },
              { id: 4, value: "Oil and Gas Industry" }]}
              modalShow={this.showMoreDetails} />
          </section>
          {(this.state.showModal) ? <Modal show={this.state.showModal} modalClosed={this.closeShowMore}>{learnMore}</Modal> : null}

          <div onClick={() => this.scrollToMyRef('arrow')} className="arrow bounce" />


        </Layout>
      </div>
    );
  }
}

export default About;