import React, { Component } from 'react';
import './CertCard.css';
import ScrollAnimation from 'react-animate-on-scroll';

class CertCard extends Component {



    render() {

        let side = 1;
        let headerAnimation = "slideInLeft";
        if (this.props.sequence % 2 === 0) {
            side = 2;
            headerAnimation = "slideInRight";

        }

        let attachedClasses = ["section"];
        if (side && side === 1) {
            attachedClasses = ["section", "section-light"];
        } else if (side && side === 2) {
            attachedClasses = ["section", "section-light2"];
        } else {
            attachedClasses = ["section", "section-hidden"];
        }

        return (
            <div>
                <section className={attachedClasses.join(' ')}>
                    <ScrollAnimation animateIn={headerAnimation} offset={50}>
                        <h2 className="my-header">{this.props.header}</h2>
                    </ScrollAnimation>
                </section>
                <div className="pimg biZcert" >
                    <div className="bizDiv">
                        <img src={process.env.PUBLIC_URL+this.props.certImg} alt="cert" className="bizImg" />
                    </div>
                </div>
            </div>
        );
    }
}

export default CertCard;