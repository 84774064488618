import React, { useState } from 'react';
import axios from 'axios';
import notie from "notie";
import { ButtonToolbar } from 'react-bootstrap';


const AddNewImage = (props) => {
    const [file, setFile] = useState('');
    const [filename, setFilename] = useState('Choose File');
    // const [uploadedFile, setUploadedFile] = useState({});
    // const [message, setMessage] = useState('');
    // const [uploadPercentage, setUploadPercentage] = useState(0);
    
    const onChange = e => {
        setFile(e.target.files);
        var ins =e.target.files.length;
        let fileNames="";
        for (var x = 0; x < ins; x++) {
            fileNames=fileNames+e.target.files[x].name;
            if(x !== ins - 1)
                fileNames += " , ";
        }
        console.log(fileNames)
        if(fileNames.length === 0)
            fileNames = 'Choose File';
        setFilename(fileNames);
    };


    const onSubmit = async e => {
        e.preventDefault();
        var ins = file.length;
        const formData = new FormData();
        for (var x = 0; x < ins; x++) {
            formData.append("file", file[x]);
        }
        console.log(props.imgFolderPath);
        await axios.post('/api/upload', formData, {
           
            headers: {
                keyId:props.keyId,
                imgFolderPath:props.imgFolderPath,
                'Content-Type': 'multipart/form-data'
            }
        }).then(successUpload).catch(errorUpload);



    };
    const successUpload = (response) => {
        if (response.data && response.status === 200) {
            var newImageList = response.data.filePathList;
            var oldImageList = props.colImageList;
            var finalImageList = oldImageList.concat(newImageList);
            props.setColImageList(finalImageList);
            setFile('');
            setFilename('Choose File');
            notie.alert({ type: 'success', text: 'File Uploaded Successfully!', time:2 });
        }
    };
    const errorUpload = (err) => {
        if (err.response.status === 500) {
            notie.alert({ type: 'error', text: 'There was a problem with the server', time: 2 });
        } else {
            notie.alert({ type: 'error', text: err.response.data.msg, time: 2 });
        }

        // try {
        //     const res = await axios.post('/api/upload', formData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         },
        //         onUploadProgress: progressEvent => {
        //             setUploadPercentage(
        //                 parseInt(
        //                     Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //                 )
        //             );
        //             // Clear percentage
        //             setTimeout(() => setUploadPercentage(0), 10000);
        //         }
        //     });

        //     // const { fileName, filePath } = res.data;
        //     // setUploadedFile({ fileName, filePath });

        //     new Noty({
        //         text: "File Uploaded but json not updated[to build]..",
        //         layout: "top",
        //         type: "success",
        //         theme: "mint",
        //         timeout: 5000
        //     }).show();

        // } catch (err) {
        //     if (err.response.status === 500) {
        //         new Noty({
        //             text: 'There was a problem with the server',
        //             layout: "top",
        //             type: "error",
        //             theme: "mint",
        //             timeout: 5000
        //         }).show();
        //     } else {
        //         new Noty({
        //             text: err.response.data.msg,
        //             layout: "top",
        //             type: "error",
        //             theme: "mint",
        //             timeout: 5000
        //         }).show();
        //     }
        // }
    };

    return (
        <div className="tabContentMain">
            <br />
            Number of photos: {props.numberOfPhoto}
            <br />
            <br /><br /><br />
            <form onSubmit={onSubmit}>
                <div className='custom-file mb-4'>
                    <input
                        type='file'
                        multiple
                        className='custom-file-input'
                        id={props.keyId + "customFile"}
                        onChange={onChange}
                    />
                    <label className='custom-file-label' htmlFor='customFile'>
                        {filename}
                    </label>
                </div>
                {/* <Progress percentage={uploadPercentage} /> */}
                <br />
                <ButtonToolbar className="uploadDiv">
                    <input type='submit' disabled={file.length <= 0} value='Upload' className='btn btn-primary' />
                </ButtonToolbar>
            </form>

        </div>
    );
};

export default AddNewImage;
