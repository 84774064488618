import React, { Component } from 'react';
import './Contact.css';

import contactBk from '../../assets/Images/contact/1.png';
import mapBg from '../../assets/Images/contact/2.png';

import emailIC from '../../assets/Images/contact/email.png';
import phoneIC from '../../assets/Images/contact/phone.png';
import mapIC from '../../assets/Images/contact/map2.png';


import Layout from '../../hoc/Layout/Layout';

import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';

import { Container, Row, Col, Image } from 'react-bootstrap';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    };
  }
  scrollToMyRef = () => {
    window.scrollTo({
      top: this.myRef.current.offsetTop,
      // top:250,
      behavior: 'smooth'
    })
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });


  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  componentDidMount() {
  }

  render() {
    return (
      <div className="contactMain">
      <Layout role="public">
       
          <div className="pimg" style={{ backgroundImage: "url(" + contactBk + ")" }}>
            <div className="ptext">
              <span className="borders">
                {/* sanyo.eng@gmail.com */}
                get in touch
            </span>
            </div>
          </div>

          <Container ref={this.myRef} className="contactCont">
            <h2 className="contactUsheading" >CONTACT US</h2>
            <Row className="iconRow">
              <Col><Image src={phoneIC} />
                <h5 className="iconHead">PHONE</h5>
                <p className="iconContent">TEL NO: +65 6677 2335<br />FAX NO: +65 6677 2335</p>
              </Col>
              <Col>
                <Image src={emailIC} />
                <h5 className="iconHead">EMAIL</h5>
                <p className="iconContent">SANYO.ENG@GMAIL.COM</p>
              </Col>
              <Col><Image src={mapIC} />
                <h5 className="iconHead">LOCATION </h5>
                <p className="iconContent">50 TUAS AVENUE 11 <br />#02-02 TUAS LOT <br />SINGAPORE 639107</p>
              </Col>
            </Row>

          </Container>


          {/* <div className="sendMessDiv">
            <Container>
              <h2 className="contactUsheading">ENQUIRY</h2>
              <Form>
                <Form.Group controlId="exampleForm.ControlInputName">
                  <Form.Row>
                    <Col>
                      <Form.Control placeholder="First name" />
                    </Col>
                    <Col>
                      <Form.Control placeholder="Last name" />
                    </Col>
                  </Form.Row>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInputEmail">
                  <Form.Control type="email" placeholder="name@example.com" />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextMess">
                  <Form.Control as="textarea" rows="3" placeholder="Message" />
                </Form.Group>
                <Button type="submit" className="sendMessage">SEND MESSAGE</Button>
              </Form>
            </Container>
          </div> */}




          <div className="pimg" style={{ backgroundImage: "url(" + mapBg + ")" }}>
            <Container className="mapContainer">
              <Map className="mapContent" onClick={this.onMapClicked}
                google={this.props.google}
                zoom={11}
                initialCenter={{ lat: 1.320109, lng: 103.629764 }}
              >
                <Marker position={{ lat: 1.320109, lng: 103.629764 }} name="50 Tuas Ave 11, #02-02 Tuas Lot, Singapore 639107" onClick={this.onMarkerClick} link="https://www.google.com/maps/dir/?api=1&destination=Sanyo%20Engineering%20Pte.%20Ltd.%2C%2050%20Tuas%20Ave%2011%2C%20%2302-02%2C%20Tuas%20Lot%2C%20Singapore%20639107" />
                <InfoWindow
                  marker={this.state.activeMarker}
                  visible={this.state.showingInfoWindow}>
                  <div>
                    <p className="addressContent">{this.state.selectedPlace.name}</p>
                    <a href={this.state.selectedPlace.link} target="_blank" rel="noopener noreferrer" className="directionContent">Directions</a>
                  </div>
                </InfoWindow>
              </Map>
            </Container>
          </div>


          <div onClick={this.scrollToMyRef} className="arrow bounce" />
        
      </Layout>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAm4Bwv3ZBguWHRtIZLcjiKa3GVD_3Pkhs'
})(Contact);;