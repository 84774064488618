import React, { } from 'react';

import { Row, Col, Card, Container } from 'react-bootstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, } from '@fortawesome/free-solid-svg-icons'
import {
  faGoogle,
  faFacebook,
  faTwitter,
  faYoutube,
  faLinkedin,
  faLinkedinIn
} from '@fortawesome/free-brands-svg-icons';
import { Route } from 'react-router-dom';

import ScrollArrow from './ScrollArrow';

import "./Footer.css"
// import logoImage from '../../assets/Images/logo.png';
import logoImage from '../../assets/Images/footer/logoFooter.png';

const Footer = (props) => {


  const gotoAboutPage = (scrollRef, serTittle) => {
    return <Route render={({ history }) => (
      <a className="ftrLinkSer" herf onClick={() => {
        history.push({
          pathname: '/about',
          scrollRef: scrollRef,
        });
      }}>{serTittle}</a>
    )} />;
  }




  return (
    <footer className="FooterMain">

      <Container>


        <Row >
          <Col lg={{ span: 8 }}>
            <Row>
              <Col className="footerIconDiv" lg={{ span: 2 }} sm={{ span: 4 }} xs={{ span: 4 }}>
                <Card className="FooterLogo">
                  <Card.Img src={logoImage} />
                </Card>

              </Col>
              <Col className="footerIconDiv1" lg={{ span: 10 }} sm={{ span: 8 }} xs={{ span: 8 }}>
                <h3 className="footerCompanyName">SANYO ENGINEERING PTE LTD</h3>
                <h5 className="footerQoute"><q>Construction at its best</q></h5>
              </Col>
            </Row>
          </Col>
          <Col lg={{ span: 4 }} className="footerRight d-flex flex-row-reverse" >

          
          <a href="https://www.linkedin.com/in/sanyo-engineering-pte-ltd-a7002a185/" target="_blank">
              <FontAwesomeIcon icon={faLinkedin} size="3x" className="socalIcons" />
            </a>
           
            <a href="https://www.google.com/search?q=Sanyo%20Engineering%20Pte.%20Ltd" target="_blank">
            <FontAwesomeIcon icon={faGoogle} size="3x" className="socalIcons" />
            </a>
            <a href="https://www.facebook.com/Sanyo-Engineering-Pte-Ltd-101187761896553" target="_blank">
              <FontAwesomeIcon icon={faFacebook} size="3x" className="socalIcons" />
            </a>
          </Col>

        </Row>

        <Row >

          <Col lg={{ span: 12 }}>
            <div className="brdr">
            </div>
          </Col>
        </Row>
        <Row >


          <Col lg={{ span: 4 }} className="">
            <a href="/" className="ftrMainlink"><b>WHAT WE DO</b></a>
            <p>
              Sanyo Engineering Pte Ltd was establish in Feb 2014, as a young, dynamic contractor specializing in the Integrated Civil Engineering, Project Management and M&E Works. We have been constantly striving hard to greater heights through hard work, innovation, value engineering and sustainable progressive growth providing responsive and quality services to our clients.
              </p>
          </Col>
          <Col lg={{ span: 2 }} className="">
            <ul>
              <li><a href="/" className="ftrMainlink"><b>MENU</b></a></li>
              <li><a href="/" className="ftrLink">Home</a></li>
              <li><a href="/about" className="ftrLink">About</a></li>
              <li><a href="/project" className="ftrLink">Project</a></li>
              <li><a href="/contact" className="ftrLink">Contact Us</a></li>
              <li><a href="/cert" className="ftrLink">Certifications</a></li>
            </ul>

          </Col>


          <Col lg={{ span: 3 }} className="">
            <ul>
              <li><a href="/about" className="ftrMainlink"><b>SERVICES</b></a></li>
              <li>{gotoAboutPage("plantCivikWorks", "Plant Civil Works")}</li>
              <li>{gotoAboutPage("blastingWorks", "Blasting/Painting Works")}</li>
              <li>{gotoAboutPage("scaffoldingWorks", "Scaffolding Works")}</li>
              <li>{gotoAboutPage("insulationWorks", "Insulation Works")}</li>
              <li>{gotoAboutPage("maintenancePlant", "Maintenance Process Plant")}</li>
              <li>{gotoAboutPage("manPowerProvision", "Manpower Provision")}</li>
            </ul>
          </Col>
          <Col lg={{ span: 3 }} className="">
            <a href="/contact" className="ftrMainlink"><b>CONTACT</b></a>
            <p className="">
                50 TUAS AVENUE 11 <br />
                #02-02 TUAS LOT <br />
                SINGAPORE 639107 <br /><br />
                Tel no: +65 6677 2335 <br />
                Fax no: +65 6677 2335 <br /><br />
              <FontAwesomeIcon icon={faEnvelope} />
          &nbsp;admin@sanyoeng.com
          </p>
          </Col>


        </Row>


        <Row >

          <Col lg={{ span: 12 }}>
            <div className="brdr">
            </div>
          </Col>


        </Row>


        <div className="copyWrite">
          Sanyo Engineering Pte Ltd. All Rights Reserved © 2020
        </div>
      </Container>
      <ScrollArrow />
    </footer>
  );
}



export default Footer;