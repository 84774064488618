import React, { Component } from 'react';
import './projectCard.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Card, Col, Row, Table, Carousel } from 'react-bootstrap';
import projectBk from '../../../assets/Images/project/1.png';

import logoImage from '../../../assets/Images/logo.png';

class ProjectCard extends Component {



    render() {

        let side = 1;
        let headerAnimation = "slideInLeft";
        let order = "0";

        if (this.props.sequence % 2 === 0) {
            side = 2;
            headerAnimation = "slideInRight";
            order = "1"
        }
        

        const carouselItems = this.props.colImageList.map((item, index) => 
            <Carousel.Item key = {index}>
                <img className="d-block w-100 myColheight" 
                src={process.env.PUBLIC_URL+item} 
                alt={"missing image"+ item} />
            </Carousel.Item>
        );

        let attachedClasses = ["section"];
        if (side && side === 1) {
            attachedClasses = ["section", "section-light"];
        } else if (side && side === 2) {
            attachedClasses = ["section", "section-light2"];
        } else {
            attachedClasses = ["section", "section-hidden"];
        }



        return (

            <div>
                <section className={attachedClasses.join(' ')}>
                    <ScrollAnimation animateIn={headerAnimation} offset={50}>
                        <h2 className="my-header">{this.props.header}</h2>
                    </ScrollAnimation>
                </section>
                <div className="pimgProject" style={{ backgroundImage: "url(" + projectBk + ")" }}>
                    <div className="pcard2">
                        <Row>
                            <Col className="stepImage" lg={{ span: 6, order: order }}>
                                <Carousel>
                                    {carouselItems}
                                </Carousel>
                            </Col>
                            <Col className="pcardCont" lg={{ span: 6 }}>
                                <Card className="pcardPara2" bg="light" text="black" >
                                    <Card.Header><img src={logoImage} alt="logo" className="LearnMoreLogo" /></Card.Header>
                                    <Card.Body>
                                        <Card.Header>{this.props.description}</Card.Header>
                                        <Table striped bordered hover responsive>
                                            <tbody>
                                                <tr>
                                                    <td>Client/Owner</td>
                                                    <td>{this.props.client}</td>
                                                </tr>
                                                <tr>
                                                    <td>Main Contractor</td>
                                                    <td>{this.props.mainContractor}</td>
                                                </tr>
                                                <tr>
                                                    <td>Scope of Work</td>
                                                    <td>{this.props.scope}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectCard;