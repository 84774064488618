import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './containers/Home/Home'
import Cert from "./containers/Certs/Cert"
import About from './containers/About/About';
import Project from './containers/Project/Project';
import Contact from './containers/Contact/Contact';
import Upload from './containers/Admin/Upload/Upload';
import EditProjects from './containers/Admin/Projects/EditProjects';
// import EditProjectOthers from './containers/Admin/Projects/EditProjectOthers';
import EditClients from './containers/Admin/Clients/EditClients';
import Login from './containers/Admin/Login/Login';
import EditCert from './containers/Admin/Certificates/EditCert';
import NotFound from './components/UI/NotFound/NotFound';

import Auth from "./containers/Admin/Auth";

class App extends Component {


  constructor(props) {
    super(props)
    this.state = {
      isValidUser: '',
      render: false,
      screenOrientation: 'portrait'
    };
  }

  handleAuthState = authState => {
    this.setState({ authState })
  }

  componentDidMount() {

    Auth.loadCurrentUser();

    setTimeout(() => {
      this.setState({
        render: true
      });
    }, 500)
  }

  render() {

    if (!this.state.render) return null


    // alert("render");
    // alert(this.state.isValidUser);

    function PrivateRoute({ component: Component, ...rest }) {
      return (
        <Route
          {...rest}
          render={(props) => {
            var result = Auth.getAuthenticated();
            console.log("result from app: " + result);
            if (result) {
              return <Component {...props} />
            } else {
              console.log("redirecting to login");
              return <Redirect to={{ pathname: '/login' }} />
            }
          }} />
      );
    }

    return (
      
      <div className={"orientation " + (this.state.screenOrientation === 'landscape' ? 'orientationLandscape' : 'orientationPortrait')}>
        <div id="fb-root"></div>
        <div className="fb-customerchat"
              page_id="101187761896553"
              theme_color="#0A7CFF">
        </div>
        <Switch>
          <PrivateRoute exact path="/edit_project" component={EditProjects} />
          {/* <PrivateRoute exact path="/edit_project_others" component={EditProjectOthers} /> */}
          <PrivateRoute exact path="/edit_cert" component={EditCert}  />
          <PrivateRoute exact path="/edit_clients" component={EditClients}  />
          
          <Route exact path="/login" component={Login} />


          <Route path="/upload" component={Upload} />
          <Route path="/about" component={About} />
          <Route path="/project" component={Project} />
          <Route path="/contact" component={Contact} />
          <Route path="/cert" component={Cert} />
          <Route path="/" exact component={Home} />

          <Route path="*" component={NotFound} />
        </Switch>
      </div>
    );
  }
}

export default App;
