import React, { Component } from 'react';
import './Home.css';
import homeBg1 from '../../assets/Images/home/1.png';
import homeBg6 from '../../assets/Images/home/6.png';
import ScrollAnimation from 'react-animate-on-scroll';
import Layout from '../../hoc/Layout/Layout';
import { Row, Col, Card} from 'react-bootstrap';

import "animate.css/animate.min.css";
import '../../components/UI/Hover/customHover.css'
import aboutBg2 from '../../assets/Images/about/2.png';
import aboutBg3 from '../../assets/Images/about/3A.png';
import aboutBg4 from '../../assets/Images/about/4.png';
import aboutBg5 from '../../assets/Images/about/5.png';
import aboutBg6 from '../../assets/Images/about/6.png';
import aboutBg7 from '../../assets/Images/about/7.png';


// import client1 from '../../assets/Images/home/clients/1.png'
// import client2 from '../../assets/Images/home/clients/2.png'
// import client3 from '../../assets/Images/home/clients/3.png'
// import client4 from '../../assets/Images/home/clients/4.png'
// import client5 from '../../assets/Images/home/clients/5.png'
// import client6 from '../../assets/Images/home/clients/6.png'
// import client7 from '../../assets/Images/home/clients/7.png'
// import client8 from '../../assets/Images/home/clients/8.png'
// import client9 from '../../assets/Images/home/clients/9.png'
// import client10 from '../../assets/Images/home/clients/10.png'
// import client11 from '../../assets/Images/home/clients/11.png'
// import client12 from '../../assets/Images/home/clients/12.png'
// import client13 from '../../assets/Images/home/clients/13.png'
// import client14 from '../../assets/Images/home/clients/14.png'
import axios from 'axios';

import { Route } from 'react-router-dom';

class Home extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      clients: [],
    };
  }

  scrollToMyRef = () => {
    window.scrollTo({
      top: this.myRef.current.offsetTop,
      // top:250,
      behavior: 'smooth'
    })
  }


  componentDidMount() {
    axios.get('/api/getContent')
      .then(response => {
        this.setState({
          clients: response.data.clients
          
        })
      })
    }

  render() {

    return (
      <div className="App">
        <Layout role="public" >

          <div className="pimg" style={{ backgroundImage: "url(" + homeBg1 + ")" }}>
            <div className="ptext">
              <span className="borders">
                Construction at its best
            </span>
            </div>
          </div>
          <div ref={this.myRef} className="homeContainer">
            <Row>
              <Col className="leftColMain" lg={{ span: 6 }}>
                <ScrollAnimation animateIn="fadeInLeft" offset={50}>
                  <h2 className="my-header">OUR MISSION</h2>

                  <Card className="cardsCont" text="black" >
                    {/* <Card.Header>OUT MOTO</Card.Header> */}
                    <Card.Body className="">
                      <q>To deliver services and products safely in excellent standards
                      for the benefit of stakeholders and the community
                  </q>
                    </Card.Body>
                  </Card>
                </ScrollAnimation>
              </Col>
              <Col className="leftColMain" lg={{ span: 6 }}>
                <ScrollAnimation animateIn="fadeInRight" offset={50}>
                  <h2 className="my-header">Our vision</h2>

                  <Card className="cardsCont" text="black" >
                    <Card.Body className="">
                      <q>Strive to deliver quality services in efficient & effective
                      procedures. Innovating & developing
                      resources for the future
                  </q>
                    </Card.Body>
                  </Card>
                </ScrollAnimation>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 3 }}></Col>
              <Col className="ourMotoClass" lg={{ span: 6 }}>
                <ScrollAnimation animateIn="flipInY" offset={50}>
                  <h2 className="my-header">OUR MOTO</h2>
                  <Card className="cardsCont" text="black" >
                    <Card.Body className="">
                      <q>YOUR SATISFACTION IS OUR PRIORITY
                  </q>
                    </Card.Body>
                  </Card>
                </ScrollAnimation>
              </Col>
              <Col lg={{ span: 3 }}></Col>
            </Row>

          </div>
          {/* <section ref={this.myRef} className="section section-light2">
            <ScrollAnimation animateIn="slideInRight" offset={50}>
              <h2 className="my-header">OUR MISSION</h2>
            </ScrollAnimation>
          </section>
          <div className="pimg" style={{ backgroundImage: "url(" + homeBg2 + ")" }}>
            <div className="ptext-quote">
              <div className="quote-container">
                <div className="quote-text">
                  To deliver services & products safely in excellent standards for the benefit of stakeholders and the community
            </div>
              </div>
            </div>
          </div> */}
          <section className="section section-light">
            <ScrollAnimation animateIn="fadeInLeft" offset={50}>
              <h2 className="my-header">Our services</h2>
            </ScrollAnimation>
          </section>
          <div className="pimg pimg3" style={{ backgroundImage: "url(" + homeBg6 + ")" }}>
            <Row className="ourServiceMainRow">
            
              <Col className="serviceImageCol" lg={{ span: 4 }} sm ={{ span: 6 }}>
              <Card className="snip1321" >
                  <Card.Img variant="top" src={aboutBg2} />
                  <figcaption>
                    <h4>Plant Civil Works</h4>
                    <h2>Ensuring the Results You Need and Deserve</h2>
                    {this.gotoAboutPage("plantCivikWorks")}
                  </figcaption>
                  {/* <a href="/"></a> */}
                  <Card.Body>
                    <Card.Title>Plant Civil Works</Card.Title>

                  </Card.Body>
                </Card>
              </Col>
              <Col className="serviceImageCol" lg={{ span: 4 }} sm ={{ span: 6 }}>
              <Card className="snip1321">
                  <Card.Img variant="top" src={aboutBg6} />
                  <figcaption>
                    <h4>Blasting/Painting Works</h4>
                    <h2>Good Quality Finish</h2>
                    {this.gotoAboutPage("blastingWorks")}
                  </figcaption>
                  <Card.Body>
                    <Card.Title>Blasting/Painting Works</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="serviceImageCol" lg={{ span: 4 }}  sm ={{ span: 6 }}>

              <Card className="snip1321">
                  <Card.Img variant="top" src={aboutBg3} />
                  <figcaption>
                    <h4>Scaffolding Works</h4>
                    <h2>Built With Good Support</h2>
                    {this.gotoAboutPage("scaffoldingWorks")}
                  </figcaption>
                  <Card.Body>
                    <Card.Title>Scaffolding Works</Card.Title>

                  </Card.Body>

                </Card>
              </Col>
              
              <Col className="serviceImageCol serviceImageColSecondRow" lg={{ span: 4 }}  sm ={{ span: 6 }}  >
              <Card className="snip1321">
                  <Card.Img variant="top" src={aboutBg4} />
                  <figcaption>
                    <h4>Insulation Works</h4>
                    <h2>Attention to Details</h2>
                    {this.gotoAboutPage("insulationWorks")}
                  </figcaption>
                  <Card.Body>
                    <Card.Title>Insulation Works</Card.Title>
                  </Card.Body>

                </Card>
              </Col>
              
              <Col className="serviceImageCol serviceImageColSecondRow" lg={{ span: 4 }} sm ={{ span: 6 }}>
              <Card className="snip1321">
                  <Card.Img variant="top" src={aboutBg7} />
                  <figcaption>
                    <h4>Maintenance Process Plant</h4>
                    <h2>Professionalism, Efficiency and Exceptional Results</h2>
                    {this.gotoAboutPage("maintenancePlant")}
                  </figcaption>
                  <Card.Body>
                    <Card.Title>Maintenance Process Plant</Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col className="serviceImageCol serviceImageColSecondRow" lg={{ span: 4 }} sm ={{ span: 6 }}>
              <Card className="snip1321">
                  <Card.Img variant="top" src={aboutBg5} />
                  <figcaption>
                    <h4>Manpower Provision</h4>
                    <h2>Skilled and Experience</h2>
                    {this.gotoAboutPage("manPowerProvision")}
                  </figcaption>
                  <Card.Body>
                    <Card.Title>Manpower Provision</Card.Title>
                  </Card.Body>
                </Card>
              </Col>              

            </Row>
          </div>
          <section className="section section-light2">
            <ScrollAnimation animateIn="fadeInRight" offset={50}>
              <h2 className="my-header">OUR CLIENTS</h2>
            </ScrollAnimation>
          </section>
          <div className="pimg pimg3">
          <Row className="ourClientMainRow">
          {this.state.clients.filter(client => client.visible==="true").map(filteredClient=> (
            <Col className="ourClientsCol" lg={{ span: 2 }} sm ={{ span: 4 }} xs ={{ span: 6 }}>
            <Card className="snip1573" >
                    <Card.Img src={filteredClient.colImageList[0]} />
                    <figcaption>
                      <h3><span>{filteredClient.heading}</span></h3>
                    </figcaption>
                  </Card>
            </Col>
          ))}
          
          
          </Row>
          </div>
          <div onClick={this.scrollToMyRef} className="arrow bounce" />

        </Layout>
      </div>
    );
  }

  gotoAboutPage(scrollRef) {
    return <Route render={({ history }) => (
      <button className ="LearnMore" type='button' onClick={() => {
        history.push({
          pathname: '/about',
          scrollRef: scrollRef,
        });
      } }>LEARN MORE</button>
    )} />;
  }
}

export default Home;
