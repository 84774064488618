import React, { Component } from 'react';
import './Cert.css';
import certBg1 from '../../assets/Images/cert/certBk3.jpeg';
import Layout from '../../hoc/Layout/Layout';
import CertCard from '../../components/UI/CertCard/CertCard'
import axios from 'axios';


class Cert extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      certificates: [],
    };
  }

  scrollToMyRef = () => {
    window.scrollTo({
      top: this.myRef.current.offsetTop,
      behavior: 'smooth'
    })
  }


  componentDidMount() {
    axios.get('/api/getContent')
      .then(response => {
        // console.log(response.data);
        this.setState({
          certificates: response.data.certs
          
        })
      })
  }

  render() {
    return (
      <div className="App">
      <Layout role="public">
        
          <div className="pimg" style={{ backgroundImage: "url(" + certBg1 + ")" }}>
            <div className="ptext">
              <span className="borders">
                We are certified
            </span>
            </div>
          </div>
          <div ref={this.myRef}>
          {this.state.certificates.filter(cert => cert.visible==="true").map((filteredCert, index)=> (
            <CertCard
            key={index}
            sequence={index}
            header={filteredCert.heading}
            certImg={filteredCert.colImageList[0]}/>
            ))}
          </div>
          <br></br>
          <br></br>
          <div onClick={this.scrollToMyRef} className="arrow bounce" />
        
      </Layout>
      </div>
    );
  }
}

export default Cert;
