import React, { useState } from "react";
import Switch from "react-switch";



const ToggleSwitch = (props) => {
  const [checked, setChecked] =useState(props.visible);

  const handleChange=(checked,event)=> {
        setChecked(checked.toString());
        var newTarget={target: {name:"visible",value: checked.toString()}}
        
        const { name, value } = newTarget.target;
        console.log(name + " - " + value)
        props.handleProjectsOnChange(newTarget);
      }

  return (
    <label>
      <Switch type="checkbox"   name="visible" onChange={handleChange} 
      checked={checked === 'true'} />
    </label>
  );
}

 
// class ToggleSwitch extends Component {
//   constructor() {
//     super();
//     this.state = { checked: false };
//     this.handleChange = this.handleChange.bind(this);
//   }
 
//   handleChange(checked) {
//     this.setState({ checked });
//   }
 
//   render() {
//     // alert(this.props.visible);
//     this.setState({checked:this.props.visible});
//     return (
//       <label>
//         <Switch name='visible' onChange={this.handleChange} checked={this.state.checked} />
//       </label>
//     );
//   }
// }

export default ToggleSwitch;